// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'staging',
  production: false,
  apiUrl: 'https://api-staging.whatsauto.com.br',
  brokerOfficialWSUrl: 'https://brokerstaging-ws.whatsauto.com.br',
  chatFlowAppAddress: 'https://staging-chatflow.whatsauto.com.br',
  CDN_URL: 'https://staging-cdn.whatsauto.com.br/files',
  audioConverterUrl: 'https://audioconv.whatsauto.com.br/convert',
  brand: 'whatsauto',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
